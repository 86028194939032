import React from 'react';
import cntl from 'cntl';
import { classNames } from '../../../utils';
import { ServiceTypeEnum } from '../../../@types';

interface ServiceTypeProps {
  selected: ServiceTypeEnum;
  onSelect: (selected: ServiceTypeEnum) => void;
}

const containerClassName = cntl`
  sm:flex
  sm:flex-col
  sm:align-center
`;

const wrapperClassName = cntl`
  relative
  self-center
  mt-6
  bg-gray-100
  rounded-lg
  p-0.5
  flex
  sm:mt-8
`;

const buttonClassName = cntl`
  relative
  w-1/2
  rounded-md
  py-2
  text-sm
  font-medium
  whitespace-nowrap
  focus:outline-none
  focus:z-10
  sm:w-auto
  sm:px-8
`;

const normalButtonClassName = cntl`
  ml-0.5
  border
  border-transparent
  text-gray-700
`;

const activeButtonClassName = cntl`
  bg-primary-500
  text-white
`;

const ServiceType: React.FC<ServiceTypeProps> = ({ selected, onSelect }) => {
  return (
    <div className={containerClassName}>
      <div className={wrapperClassName}>
        <button
          type="button"
          className={classNames(
            buttonClassName,
            selected === 'Detailing'
              ? activeButtonClassName
              : normalButtonClassName
          )}
          onClick={() => onSelect('Detailing')}
        >
          Detailing
        </button>
        <button
          type="button"
          className={classNames(
            buttonClassName,
            selected === 'Coating'
              ? activeButtonClassName
              : normalButtonClassName
          )}
          onClick={() => onSelect('Coating')}
        >
          Ceramic Coating
        </button>
      </div>
    </div>
  );
};

export default ServiceType;
