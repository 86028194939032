import React from 'react';
import cntl from 'cntl';
import { ContentfulServicePricing } from '../../../@types';

interface ServiceCardProps {
  servicePricing: ContentfulServicePricing;
}

const containerClassName = cntl`
  flex
  flex-col
  justify-between
  bg-white
  rounded-lg
  shadow-lg
  overflow-hidden
  mt-10
`;

const wrapperClassName = cntl`
  px-4
  py-8
  sm:p-6
  sm:pb-6
`;

const titleClassName = cntl`
  inline-flex
  px-4
  py-1
  rounded-full
  text-xs
  font-semibold
  tracking-wide
  uppercase
  bg-primary-100
  text-primary-600
`;

const priceClassName = cntl`
  mt-4
  flex
  items-baseline
  text-3xl
  font-extrabold
`;

const descriptionClassName = cntl`
  mt-5
  text-sm
  text-gray-500
`;

const ServiceCard: React.FC<ServiceCardProps> = ({ servicePricing }) => {
  return (
    <div className={containerClassName}>
      <div className={wrapperClassName}>
        <div>
          <h3 className={titleClassName}>{servicePricing.service.name}</h3>
        </div>
        <div className={priceClassName}>${servicePricing.price}</div>
        <p className={descriptionClassName}>
          {servicePricing.service.description}
        </p>
      </div>
    </div>
  );
};

export default ServiceCard;
