import cntl from 'cntl';
import React, { Fragment } from 'react';
import {
  ContentfulCarType,
  ItemList,
  CarTypeSelectorValue,
} from '../../../@types';
import { classNames } from '../../../utils';
import CarTypesMobile from './car-types-mobile';

import smallSUVSvg from '../../../assets/car-types/small-suv.svg';
import smallTruckSvg from '../../../assets/car-types/small-truck.svg';
import largeSUVSvg from '../../../assets/car-types/large-suv.svg';
import largeTruckSvg from '../../../assets/car-types/large-truck.svg';
import convertibleSvg from '../../../assets/car-types/convertible.svg';
import coupeSvg from '../../../assets/car-types/coupe.svg';
import hatchbackSvg from '../../../assets/car-types/hatchback.svg';
import minivanSvg from '../../../assets/car-types/minivan.svg';
import sedanSvg from '../../../assets/car-types/sedan.svg';
import wagonSvg from '../../../assets/car-types/wagon.svg';

const SVG_SOURCE_MAP: { [key: string]: string } = {
  'Small SUV': smallSUVSvg,
  'Small Truck': smallTruckSvg,
  'Large SUV': largeSUVSvg,
  'Large Truck': largeTruckSvg,
  Convertible: convertibleSvg,
  Coupe: coupeSvg,
  Hatchback: hatchbackSvg,
  Minivan: minivanSvg,
  Sedan: sedanSvg,
  Wagon: wagonSvg,
};

const wrapperClassName = cntl`
  mt-12
  hidden
  md:grid
  md:grid-cols-5
  md:gap-2
`;

const carTypeContainerClassName = cntl`
  p-10
  cursor-pointer
  hover:bg-primary-100
  rounded-sm
  flex
  items-center
  justify-center
`;

const iconClassName = cntl`
  h-16
  w-32
  mx-auto
`;

const titleClassName = cntl`
  mt-4
  text-center
`;

const mobileContainerClassName = cntl`
  block
  md:hidden
`;

interface CarTypesProps {
  carTypes: ItemList<ContentfulCarType>;
  selected: CarTypeSelectorValue;
  onSelect: (selected: CarTypeSelectorValue) => void;
}

const CarTypes: React.FC<CarTypesProps> = ({
  carTypes,
  selected,
  onSelect,
}) => {
  return (
    <>
      <div className={wrapperClassName}>
        {carTypes.items.map(carType => (
          <Fragment key={carType.sys.id}>
            {carType.subTypes.map(subType => {
              return (
                <div
                  key={subType}
                  className={classNames(
                    carTypeContainerClassName,
                    selected.subType === subType ? 'bg-primary-100' : ''
                  )}
                  onClick={() =>
                    onSelect({ carTypeId: carType.sys.id, subType })
                  }
                >
                  <div>
                    <img
                      className={iconClassName}
                      src={SVG_SOURCE_MAP[subType]}
                      alt={subType}
                    />
                    <div className={titleClassName}>{subType}</div>
                  </div>
                </div>
              );
            })}
          </Fragment>
        ))}
      </div>
      <div className={mobileContainerClassName}>
        <CarTypesMobile
          selected={selected}
          onSelect={onSelect}
          carTypes={carTypes}
        />
      </div>
    </>
  );
};

export default CarTypes;
