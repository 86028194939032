import React from 'react';
import cntl from 'cntl';

interface LinkGroupProps {
  title: string;
}

const containerClassName = cntl`
  my-16
  md:mt-0
`;

const titleClassName = cntl`
  text-sm
  font-semibold
  text-gray-400
  tracking-wider
  uppercase
`;

const listClassName = cntl`
  mt-4
  space-y-4
`;

const LinkGroup: React.FC<LinkGroupProps> = ({ title, children }) => {
  return (
    <div className={containerClassName}>
      <h3 className={titleClassName}>{title}</h3>
      <ul role="list" className={listClassName}>
        {children}
      </ul>
    </div>
  );
};

export default LinkGroup;
