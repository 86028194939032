import React from 'react';
import cntl from 'cntl';
import Stars from '../../stars';
import googleIconSrc from '../../../assets/social/google.svg';

interface AuthorProps {
  author: string;
  carModel: string;
}

const containerClassName = cntl`
  inline-flex
  items-center
  divide-x
  divide-gray-300
`;

const authorClassName = cntl`
  text-left
  font-medium
  pr-5
  text-sm
  text-gray-900
`;

const ratingsClassName = cntl`
  px-5
  flex
  items-center
`;

const googleIconClassName = cntl`
  h-4
  w-4
  mr-2
`;

const Author: React.FC<AuthorProps> = ({ author, carModel }) => {
  return (
    <footer className="mt-8">
      <div className={containerClassName}>
        <div className={authorClassName}>
          <div>{author}</div>
          <div>{carModel}</div>
        </div>
        <div className={ratingsClassName}>
          <img className={googleIconClassName} src={googleIconSrc} />
          <Stars />
        </div>
      </div>
    </footer>
  );
};

export default Author;
