import React from 'react';
import cntl from 'cntl';

interface TitleProps {
  primary: string;
  secondary: string;
}

const containerClassName = cntl`
  max-w-7xl
  mx-auto
  px-4
  sm:px-6
  lg:px-8
`;

const wrapperClassName = cntl`
  max-w-4xl
  mx-auto
  text-center
`;

const primaryTitleClassName = cntl`
  text-3xl
  font-extrabold
  text-gray-900
  sm:text-4xl
`;

const secondaryTitleClassName = cntl`
  mt-3
  text-xl
  text-gray-500
  sm:mt-4
`;

const Title: React.FC<TitleProps> = ({ primary, secondary }) => {
  return (
    <div className={containerClassName}>
      <div className={wrapperClassName}>
        <h2 className={primaryTitleClassName}>{primary}</h2>
        <p className={secondaryTitleClassName}>{secondary}</p>
      </div>
    </div>
  );
};

export default Title;
