import React from 'react';
import cntl from 'cntl';
import { StarIcon } from '@heroicons/react/solid';

const starClassName = cntl`
  h-5
  w-5
  text-yellow-400
`;

const Stars: React.FC = () => {
  return (
    <div className="flex">
      {[0, 1, 2, 3, 4].map(i => (
        <StarIcon key={i} className={starClassName} aria-hidden="true" />
      ))}
    </div>
  );
};

export default Stars;
