import React, { useState, useEffect } from 'react';

interface UseSliderHookValue {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

export default (count: number, duration: number): UseSliderHookValue => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(state => {
        const incr = state + 1;

        if (incr < count) {
          return incr;
        }

        return 0;
      });
    }, duration);

    return () => {
      clearInterval(interval);
    };
  }, [count, duration, setCurrent]);

  return { current, setCurrent };
};
