import React from 'react';
import cntl from 'cntl';
import { Testimonial } from '../../../@types';
import Author from './author';

interface CardProps {
  testimonial: Testimonial;
  hidden: boolean;
}

const descriptionClassName = cntl`
  max-w-3xl
  mx-auto
  text-center
  text-lg
  leading-9
  font-medium
  text-gray-900
`;

const Card: React.FC<CardProps> = ({ testimonial, hidden }) => {
  return (
    <blockquote className={hidden ? 'hidden' : ''}>
      <div className={descriptionClassName}>
        <p>&ldquo;{testimonial.description.description}&rdquo;</p>
      </div>
      <Author author={testimonial.author} carModel={testimonial.carModel} />
    </blockquote>
  );
};

export default Card;
