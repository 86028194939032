import React from 'react';
import cntl from 'cntl';
import { Link } from 'gatsby';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { ContentfulPackage } from '../../../@types';

const containerClassName = cntl`
  relative
  mt-20
  max-w-7xl
  mx-auto
  px-4
  sm:px-6
  lg:px-8
`;

const wrapperClassName = cntl`
  max-w-lg
  mx-auto
  rounded-lg
  shadow-lg
  overflow-hidden
  lg:max-w-none
  lg:flex
`;

const leftSectionClassName = cntl`
  flex-1
  bg-white
  px-6
  py-8
  lg:p-12
`;

const rightSectionClassName = cntl`
  py-8
  px-6
  text-center
  bg-gray-50
  lg:flex-shrink-0
  lg:flex
  lg:flex-col
  lg:justify-center
  lg:p-12
`;

const titleClassName = cntl`
  text-2xl
  font-extrabold
  text-gray-900
  sm:text-3xl
`;

const descriptionClassName = cntl`
  mt-6
  text-base
  text-gray-500
`;

const whatsIncludedClassName = cntl`
  flex-shrink-0
  pr-4
  bg-white
  text-sm
  tracking-wider
  font-semibold
  uppercase
  text-primary-600
`;

const dividerClassName = cntl`
  flex-1
  border-t-2
  border-gray-200
`;

const serviceListClassName = cntl`
  mt-8
  space-y-5
  lg:space-y-0
  lg:grid
  lg:grid-cols-2
  lg:gap-x-8
  lg:gap-y-5
`;

const serviceClassName = cntl`
  flex
  items-start
  lg:col-span-1
`;

const serviceTitleClassName = cntl`
  ml-3
  text-sm
  text-gray-700
`;

const checkIconClassName = cntl`
  h-5
  w-5
  text-primary-400
`;

const guaranteeTextClassName = cntl`
  text-lg
  leading-6
  font-medium
  text-gray-900
`;

const priceClassName = cntl`
  mt-4
  flex
  items-center
  justify-center
  text-5xl
  font-extrabold
  text-gray-900
`;

const bookButtonWrapperClassName = cntl`
  rounded-md
  shadow
`;

const learnMoreLinkWrapperClassName = cntl`
  mt-4
  text-sm
`;

const learnMoreLinkClassName = cntl`
  font-medium
  text-gray-500
  underline
`;

const bookButtonClassName = cntl`
  flex
  items-center
  justify-center
  px-5
  py-3
  border
  border-transparent
  text-base
  font-medium
  rounded-md
  text-white
  bg-primary-600
  hover:bg-primary-800
`;

interface CoatingPackageProps {
  pkg: ContentfulPackage;
}

const CoatingPackage: React.FC<CoatingPackageProps> = ({ pkg }) => {
  return (
    <div className={containerClassName}>
      <div className={wrapperClassName}>
        <div className={leftSectionClassName}>
          <h3 className={titleClassName}>{pkg.name}</h3>
          <p className={descriptionClassName}>
            Our ceramic coating creates an impenetrable layer to UV, weather,
            acids, pollutants, bird droppings, tree sap and anything else that
            threatens your vehicle’s surface.
          </p>
          <div className="mt-8">
            <div className="flex items-center">
              <h4 className={whatsIncludedClassName}>What's included</h4>
              <div className={dividerClassName} />
            </div>
            <ul role="list" className={serviceListClassName}>
              {pkg.services.items.map(service => (
                <li key={service.sys.id} className={serviceClassName}>
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className={checkIconClassName}
                      aria-hidden="true"
                    />
                  </div>
                  <p className={serviceTitleClassName}>{service.name}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={rightSectionClassName}>
          <p className={guaranteeTextClassName}>
            Keep your car looking brand new
          </p>
          <div className={priceClassName}>
            <span>${pkg.price}</span>
          </div>
          <div className="mt-6">
            <div className={bookButtonWrapperClassName}>
              <Link to="/booking" className={bookButtonClassName}>
                Book now
              </Link>
            </div>
          </div>
          <p className={learnMoreLinkWrapperClassName}>
            <a
              target="_blank"
              href="https://ceramiccoatboston.com/"
              className={learnMoreLinkClassName}
            >
              Learn about ceramic coating
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CoatingPackage;
