import React from 'react';
import cntl from 'cntl';
import { classNames } from '../../../utils';
import useSiteMetadata from '../../../hooks/useSiteMetadata';
import brandSVG from '../../../assets/brand.svg';
import happyDetailerSVG from '../../../assets/happydetailer.svg';
import { Link } from 'gatsby';

const mainTextByClassName = cntl`
  text-gray-500
  text-base
`;

const secondaryRightTextClassName = cntl`
  text-base
  text-gray-400
`;

const policyLinkClassName = cntl`
  block
  mt-2
  hover:underline
`;

const Branding: React.FC = () => {
  const { site } = useSiteMetadata();

  const poweredByCompany = 'HappyDetailer';
  const poweredByCompanyLink = 'https://happydetailer.com/';
  const brandDescription = `
    Dave's Auto Detailing is a professional automotive detailing and ceramic coating installer in Roseville, CA.
  `;

  return (
    <>
      <div className="space-y-8">
        <Link to="/">
          <img className="h-10" src={brandSVG} alt={site.siteMetadata.title} />
        </Link>
        <p className={mainTextByClassName}>{brandDescription}</p>
      </div>
      <div className={classNames(secondaryRightTextClassName, 'my-7')}>
        <p className="mb-2">Powered by</p>
        <a target="_blank" href={poweredByCompanyLink}>
          <img className="w-40" src={happyDetailerSVG} alt={poweredByCompany} />
        </a>
      </div>
      <div className={secondaryRightTextClassName}>
        <p>
          &copy; {new Date().getFullYear()} {site.siteMetadata.title}
        </p>
        <Link to="/privacy-policy" className={policyLinkClassName}>
          Privacy Policy
        </Link>
      </div>
    </>
  );
};

export default Branding;
