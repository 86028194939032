import React from 'react';
import cntl from 'cntl';
import { Link } from 'gatsby';
import { CheckIcon } from '@heroicons/react/outline';
import { ContentfulPackage } from '../../../@types';

const containerClassName = cntl`
  flex
  flex-col
  rounded-lg
  shadow-lg
  overflow-hidden
  mt-10
`;

const infoContainerClassName = cntl`
  px-6
  py-8
  bg-white
  sm:p-10
  sm:pb-6
`;

const pkgNameClassName = cntl`
  inline-flex
  px-4
  py-1
  rounded-full
  text-sm
  font-semibold
  tracking-wide
  uppercase
  bg-primary-100
  text-primary-600
`;

const pkgPriceClassName = cntl`
  mt-4
  flex
  items-baseline
  text-6xl
  font-extrabold
`;

const serviceContainerClassName = cntl`
  flex-1
  flex
  flex-col
  justify-between
  px-6
  pt-6
  pb-8
  bg-gray-50
  space-y-6
  sm:p-10
  sm:pt-6
`;

const serviceNameClassName = cntl`
  ml-3
  text-base
  text-gray-700
`;

const checkIconClassName = cntl`
  h-6
  w-6
  text-primary-500
`;

const bookButtonWrapperClassName = cntl`
  rounded-md
  shadow
`;

const bookButtonClassName = cntl`
  flex
  items-center
  justify-center
  px-5
  py-3
  border
  border-transparent
  text-base
  font-medium
  rounded-md
  text-white
  bg-primary-600
  hover:bg-primary-800
`;

interface PackageCardProps {
  pkg: ContentfulPackage;
}

const PackageCard: React.FC<PackageCardProps> = ({ pkg }) => {
  return (
    <div className={containerClassName}>
      <div className={infoContainerClassName}>
        <div>
          <h3 className={pkgNameClassName} id="tier-standard">
            {pkg.name}
          </h3>
        </div>
        <div className={pkgPriceClassName}>${pkg.price}</div>
      </div>
      <div className={serviceContainerClassName}>
        <ul role="list" className="space-y-4">
          {pkg.services.items.map(service => (
            <li key={service.sys.id} className="flex items-start">
              <div className="flex-shrink-0">
                <CheckIcon className={checkIconClassName} aria-hidden="true" />
              </div>
              <p className={serviceNameClassName}>{service.name}</p>
            </li>
          ))}
        </ul>
        <div className={bookButtonWrapperClassName}>
          <Link
            to="/booking"
            className={bookButtonClassName}
            aria-describedby="tier-standard"
          >
            Book Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PackageCard;
