import React from 'react';
import cntl from 'cntl';

const lineClassName = cntl`
  w-3/12
  h-2
  bg-gray-800
  mx-auto
  my-14
`;

const Line: React.FC = () => {
  return <div className={lineClassName} />;
};

export default Line;
