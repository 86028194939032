import React, { Fragment } from 'react';
import cntl from 'cntl';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { classNames } from '../../../utils';
import {
  CarTypeSelectorValue,
  ContentfulCarType,
  ItemList,
} from '../../../@types';

import smallSUVSvg from '../../../assets/car-types/small-suv.svg';
import smallTruckSvg from '../../../assets/car-types/small-truck.svg';
import largeSUVSvg from '../../../assets/car-types/large-suv.svg';
import largeTruckSvg from '../../../assets/car-types/large-truck.svg';
import convertibleSvg from '../../../assets/car-types/convertible.svg';
import coupeSvg from '../../../assets/car-types/coupe.svg';
import hatchbackSvg from '../../../assets/car-types/hatchback.svg';
import minivanSvg from '../../../assets/car-types/minivan.svg';
import sedanSvg from '../../../assets/car-types/sedan.svg';
import wagonSvg from '../../../assets/car-types/wagon.svg';

const SVG_SOURCE_MAP: { [key: string]: string } = {
  'Small SUV': smallSUVSvg,
  'Small Truck': smallTruckSvg,
  'Large SUV': largeSUVSvg,
  'Large Truck': largeTruckSvg,
  Convertible: convertibleSvg,
  Coupe: coupeSvg,
  Hatchback: hatchbackSvg,
  Minivan: minivanSvg,
  Sedan: sedanSvg,
  Wagon: wagonSvg,
};

const menuClassName = cntl`
  w-full
  relative
  inline-block
  mx-auto
  mt-12
`;

const menuItemsClassName = cntl`
  origin-top-right
  absolute
  right-0
  z-50
  w-full
  rounded-md
  shadow-lg
  bg-white
  ring-1
  ring-black
  ring-opacity-5
  focus:outline-none
`;

const menuButtonClassName = cntl`
  w-full
  inline-flex
  items-center
  px-6
  py-3
  border
  border-transparent
  text-base
  font-medium
  rounded-md
  shadow-sm
  text-white
  bg-primary-600
  hover:bg-primary-700
  focus:outline-none
  focus:ring-2
  focus:ring-offset-2
  focus:ring-primary-500
`;

const menuItemClassName = cntl`
  flex
  items-center
  cursor-pointer
  px-4
  py-2
  text-sm
`;

const downArrowClassName = cntl`
  -mr-1
  ml-2
  h-5
  w-5
`;

const iconClassName = cntl`
  h-8
  w-8
  mr-5
`;

interface CarTypesMobileProps {
  carTypes: ItemList<ContentfulCarType>;
  selected: CarTypeSelectorValue;
  onSelect: (selected: CarTypeSelectorValue) => void;
}

const CarTypesMobile: React.FC<CarTypesMobileProps> = ({
  carTypes,
  onSelect,
  selected,
}) => {
  return (
    <Menu as="div" className={menuClassName}>
      <div>
        <Menu.Button className={menuButtonClassName}>
          {selected.subType === '' ? 'Select car type' : selected.subType}
          <ChevronDownIcon className={downArrowClassName} aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={menuItemsClassName}>
          <div className="py-1">
            {carTypes.items.map(carType => (
              <Fragment key={carType.sys.id}>
                {carType.subTypes.map(subType => {
                  return (
                    <Menu.Item key={subType}>
                      {({ active }) => (
                        <span
                          onClick={() =>
                            onSelect({ carTypeId: carType.sys.id, subType })
                          }
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            menuItemClassName
                          )}
                        >
                          <img
                            className={iconClassName}
                            src={SVG_SOURCE_MAP[subType]}
                            alt={subType}
                          />
                          {subType}
                        </span>
                      )}
                    </Menu.Item>
                  );
                })}
              </Fragment>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default CarTypesMobile;
