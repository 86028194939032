import React from 'react';
import cntl from 'cntl';
import { classNames } from '../../../utils';
import useSiteMetadata from '../../../hooks/useSiteMetadata';
import googleIconSrc from '../../../assets/social/google.svg';
import facebookIconSrc from '../../../assets/social/facebook.svg';
import instagramIconSrc from '../../../assets/social/instagram.svg';

const containerClassName = cntl`
  mt-8
  sm:flex
  sm:justify-center
  pr-8
`;

const buttonClassName = cntl`
  inline-flex
  items-center
  w-full
  sm:w-auto
  mt-8
  sm:mt-8
  mx-2
  px-6
  py-3
  text-sm
  font-medium
  rounded-sm
  bg-white
  ring-2
  ring-offset-1
  outline-none
`;

const iconClassName = cntl`
  w-4
  h-4
  mr-5
`;

interface SocialLink {
  iconSrc: string;
  title: string;
  url: string;
  customClassName: string;
}

const SocialLinks: React.FC = () => {
  const {
    site: { siteMetadata },
  } = useSiteMetadata();

  const socialLinks: SocialLink[] = [
    {
      iconSrc: googleIconSrc,
      title: 'Read all of our real Google Reviews',
      url: siteMetadata.googleMyBusinessUrl,
      customClassName: 'text-primary-600 ring-primary-500',
    },
    {
      iconSrc: facebookIconSrc,
      title: 'View our Facebook',
      url: siteMetadata.facebookPageUrl,
      customClassName: 'text-blue-600 ring-blue-500',
    },
    {
      iconSrc: instagramIconSrc,
      title: 'View our Instagram',
      url: siteMetadata.instagramUrl,
      customClassName: 'text-pink-600 ring-pink-500',
    },
  ];

  return (
    <div className={containerClassName}>
      {socialLinks.map(({ iconSrc, title, url, customClassName }, i) => (
        <a
          key={i}
          target="_blank"
          className={classNames(buttonClassName, customClassName)}
          href={url}
        >
          <img className={iconClassName} src={iconSrc} />
          {title}
        </a>
      ))}
    </div>
  );
};

export default SocialLinks;
