import React from 'react';
import { NodeList, Testimonial } from '../../../@types';
import useSlider from '../../../hooks/useSlider';
import Card from './card';
import Dots from './dots';

interface CardSliderProps {
  testimonials: NodeList<Testimonial>;
}

const CardSlider: React.FC<CardSliderProps> = ({ testimonials }) => {
  const { current, setCurrent } = useSlider(
    testimonials.nodes.length,
    30 * 1000
  );

  return (
    <>
      {testimonials.nodes.map((t, i) => (
        <Card hidden={i !== current} testimonial={t} key={t.contentful_id} />
      ))}
      <Dots
        onDotClick={(slideIndex: number) => setCurrent(slideIndex)}
        testimonials={testimonials}
        current={current}
      />
    </>
  );
};

export default CardSlider;
