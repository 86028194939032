import React from 'react';
import cntl from 'cntl';

interface TitleProps {
  primary: string;
  secondary?: string;
}

const wrapperClassName = cntl`
  mt-1
  block
  text-3xl
  tracking-tight
  font-extrabold
  xl:text-5xl
`;

const primaryClassName = cntl`
  block
  text-gray-900
`;

const secondaryClassName = cntl`
  block
  text-primary-600
`;

const Title: React.FC<TitleProps> = ({ primary, secondary }) => {
  return (
    <h1>
      <span className={wrapperClassName}>
        <span className={primaryClassName}>{primary}</span>
        <span className={secondaryClassName}>{secondary}</span>
      </span>
    </h1>
  );
};

export default Title;
