import React from 'react';
import cntl from 'cntl';
import { classNames } from '../../../utils';
import { NodeList, Testimonial } from '../../../@types';

interface DotsProps {
  testimonials: NodeList<Testimonial>;
  current: number;
  onDotClick: (slideIndex: number) => void;
}

const dotContainerClassName = cntl`
  flex
  py-12
  justify-center
`;

const dotClassName = cntl`
  w-4
  h-4
  rounded-full
  mx-1
  cursor-pointer
`;

const Dots: React.FC<DotsProps> = ({ testimonials, current, onDotClick }) => {
  return (
    <div className={dotContainerClassName}>
      {testimonials.nodes.map((t, i) => (
        <div
          onClick={() => onDotClick(i)}
          className={classNames(
            dotClassName,
            i === current ? 'bg-primary-300' : 'bg-gray-300'
          )}
          key={t.contentful_id}
        />
      ))}
    </div>
  );
};

export default Dots;
