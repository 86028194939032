import React from 'react';
import cntl from 'cntl';
import { Link } from 'gatsby';
import Branding from '../footer/branding';
import LinkGroup from '../footer/link-group';
import { ServiceArea, NodeList } from '../../../@types';
import { getServiceAreaLinksGroupedByCounties } from '../../../utils';

const containerClassName = cntl`
  max-w-7xl
  mx-auto
  py-12
  px-4
  sm:px-6
  lg:py-16
  lg:px-8
`;

const gridClassName = cntl`
  xl:grid
  xl:grid-cols-4
  xl:gap-8
`;

const linkGroupContainerClassName = cntl`
  mt-12
  xl:mt-0
  xl:col-span-3
`;

const linkGroupWrapperClassName = cntl`
  md:grid
  md:grid-cols-4
  md:gap-0
`;

const linkClassName = cntl`
  text-base
  text-gray-500
  hover:text-gray-900
`;

interface FooterSectionProps {
  serviceAreas: NodeList<ServiceArea>;
}

const FooterSection: React.FC<FooterSectionProps> = ({ serviceAreas }) => {
  const linkGroups = getServiceAreaLinksGroupedByCounties(serviceAreas);

  // Sort by link count asc
  const sorted = Object.entries(linkGroups).sort((a, b) => {
    return a[1].length - b[1].length;
  });

  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <div className={containerClassName}>
        <div className={gridClassName}>
          <div className="xl:col-span-1">
            <Branding />
          </div>
          <div className={linkGroupContainerClassName}>
            <div className={linkGroupWrapperClassName}>
              {sorted.map(([county, links]) => (
                <LinkGroup key={county} title={county}>
                  {links.map(([path, city]) => (
                    <li key={path}>
                      <Link to={path} className={linkClassName}>
                        {city}
                      </Link>
                    </li>
                  ))}
                </LinkGroup>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
